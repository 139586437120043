.image-simple {
  img {
    display: block;
    
    &.with-shadow {
      box-shadow: 0px 10px 20px 0px rgba(65, 65, 65, 0.10), 0px 3px 10px 0px rgba(65, 65, 65, 0.10), 0px 1px 4px 0px rgba(65, 65, 65, 0.10); 
    }

    &.with-border-radius {
      border-radius: 6px; 
    }

    &.centered {
      margin: 0 auto;
    }
  }
}