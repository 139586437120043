.container-multi-columns {
  display: flex;
  gap: var(--gutter, 16px);

  @include breakpoint(medium down) {
    flex-direction: column;
  }

  & > div {

    @include breakpoint(large up) {
      height: auto;
      flex-basis: 100%;
    }
  }
}