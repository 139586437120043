.text-box {
  p {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1.25;
    font-family: $nndagny-text-regular;
    margin-bottom: 1em;

    a {
      color: $color-grey-dark !important;
      font-size: 16px;
      font-family: $nndagny-display-normal;
      line-height: 1.25;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $color-orange-dark !important;
        text-decoration: underline;
      }

      &:visited {
        color: $color-grey-dark;
      }
    }

    &.narrow {
      @include breakpoint(large up) {
        max-width: 800px;
      }
    }

    &.text-small {
      font-size: 12px;
      line-height: 1.33;
    }

    &.color-light {
      color: $color-grey-medium;
    }

    &.color-orange {
      color: $color-orange-medium !important;
    }
  }

  &.default-ol {
    ol {      
      li {
        list-style-type: decimal;
      }

      li::before {
        display: none;    
      }
    }    
  }
}