.ofe-map {
  min-width: 100%;
  background-image: var(--photo);
  background-size: cover;
  background-position: center 50px;
  background-color: $color-white;
  height: 600px;

  @include breakpoint(medium up) {
    height: 716px;
    background-position: center;
    margin: 0 -48px;
    width: calc(100% + 96px);
  }

  @include breakpoint(small only) {
    height: 675px;
    margin: 0 -24px;
    width: calc(100% + 48px);
  }

  .ofe-map-content {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 0 24px 8px 24px;
    justify-content: start;
    height: 100%;
    width: 100%;
    position: relative;
  }

  .ofe-map-item {
    background-color: $color-white;
    padding: 16px 28px 16px 16px;
    box-shadow: 0 10px 20px 0 #4141411A, 0 3px 10px 0 #4141411A;
    border-radius: 3px;
    font-weight: $global-weight-normal;

    .ofe-map-item-icon {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      margin-top: -32px;

      img {
        width: 32px;
      }
    }

    &:nth-child(1) {
      @include breakpoint(medium up) {
        width: 197px;
        position: absolute;
        top: 0;
        right: 36px;
      }

      @media screen and #{breakpoint(large up)} and #{breakpoint(xlarge down)} {
        width: 197px;
        position: absolute;
        top: 0;
        right: 26px;
      }

      .ofe-map-item-icon {
        background-color: $color-green;
      }
    }

    &:nth-child(2) {
      @include breakpoint(medium up) {
        width: 197px;
        position: absolute;
        left: 100px;
        top: 139px;
      }

      @media screen and #{breakpoint(large up)} and #{breakpoint(xlarge down)} {
        width: 197px;
        position: absolute;
        left: 26px;
        top: 139px;
      }

      .ofe-map-item-icon {
        background-color: #7EBEAF;
      }
    }

    &:nth-child(3) {
      @include breakpoint(medium up) {
        width: 266px;
        position: absolute;
        top: 271px;
        right: 16px;
      }

      @media screen and #{breakpoint(large up)} and #{breakpoint(xlarge down)} {
        width: 197px;
        position: absolute;
        top: 271px;
        right: 26px;
      }

      .ofe-map-item-icon {
        background-color: #347663;
      }
    }

    &:nth-child(4) {
      @include breakpoint(medium up) {
        width: 318px;
        position: absolute;
        bottom: 23px;
        left: 150px
      }

      @media screen and #{breakpoint(large up)} and #{breakpoint(xlarge down)} {
        width: 318px;
        position: absolute;
        bottom: 23px;
        left: 26px;
      }

      .ofe-map-item-icon {
        background-color: $color-gray-light;
      }
    }

    @include breakpoint(small only) {
      width: 100%;
    }
  }
}