.side-icon {
  @include bg-colors;

  display: flex;
  padding: 24px;
  border-radius: 3px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-wrapper {
      @include breakpoint(medium down) {
        width: 90px;
      }

      @include breakpoint(large up) {
        width: 120px;
      }

      img {
        display: block;
        width: 80%;
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }

  .content {
    padding-left: 12px;
  }

  &.as-callout {
    padding: 32px;
  }
}
