.opinions-slider {
  position: relative;
  margin-top: 32px;
  padding: 20px 0 45px;
  overflow-x: hidden;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;

    @include breakpoint(small only) {
      width: 10%;
    }

    @include breakpoint(medium up) {
      width: 20%;
    }
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 11%, rgba(255,255,255,0) 83%);
  }

  &:after {
    right: 0;
    background: linear-gradient(270deg, rgba(255,255,255,1) 11%, rgba(255,255,255,0) 83%);
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    //height: auto;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(65, 65, 65, 0.1);
    border-radius: 4px;
    cursor: pointer;

    @include breakpoint(small only) {
      width: 80%;
    }

    @include breakpoint(medium up) {
      width: 55%;
    }
  }

  .lazy-player {
    width: 100%;
  }

  .opinion {
    position: relative;
    margin: 0;
    padding: 24px 48px;

    &:before, &:after {
      //@include icon-css-mixin;
      //content: $icon-quote;
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      //color: #BDBDBD;
      background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/quote.svg");
      background-size: cover;
      //font-size: 10px;
    }

    &:before {
      top: 30px;
      left: 24px;
      transform: rotate(180deg);
    }

    &:after {
      bottom: 24px;
      right: 24px;
    }

    .opinion-badge {
      position: relative;
      display: block;
      margin-top: 22px;
      padding-left: 32px;
      text-transform: uppercase;

      &:before {
        content: attr(data-letter);
        width: 24px;
        height: 24px;
        position: absolute;
        top: -6px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 1px 0 0;
        color: $color-white;
        border-radius: 24px;
        background-color: $color-orange;
        font-size: 14px;
        font-family: $nndagny-display-normal;
        text-transform: uppercase;
      }
    }
  }

  .slider-nav {
    display: flex;
    justify-content: right;
    align-items: center;  
    position: relative;
    z-index: 5;

    .slider-opinion-nav {
      width: 24px;
      height: 24px;
      outline: 0;
      background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-right.svg");
      background-size: 7px 14px;
      background-position: center;
      background-repeat: no-repeat;

      &.slider-opinion-prev {
        transform: rotate(180deg);
      }
    }

    .slider-pagination {
      margin: 0 4px;
      color: $color-grey-light;
      font-size: 16px;
      font-family: "NNNittiGrotesk-Heading";
      line-height: 1.25;

      .swiper-pagination-current {
        color: $color-grey-dark;
      }
    }
  }
}
