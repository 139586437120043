.attachment-wrapper {
  position: relative;
  display: block;

  &:before {
    width: 24px;
    height: 24px;
    content: "";
    position: absolute;
    top: 16px;
    left: 2px;
    background: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/document-orange.svg");
    background-size: cover;
  }

  .attachment-link {
    display: flex;
    justify-content: space-between;
    //width: 100%;
    border: 0;
    border-bottom: 1px solid $color-gray-bg;
    padding: 18px 5px 18px 32px;
    font-size: 16px;
    font-family: $nngrotesk-text-heading;
    line-height: 1.25;

    .file-meta {
      color: $color-grey-light;
      font-family: $nngrotesk-text-regular;

      .file-type {
        display: inline-block;
        position: relative;
        padding-right: 10px;

        &:after {
          content: "";
          position: absolute;
          top: 8px;
          right: 0;
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background-color: $color-grey-light;
        }
      }
    }
  }

  &.narrow {
    max-width: 800px;
  }
}
