.section-superbrands-tabs {
    background-color: transparent;
    padding: 0;

    & > .row {
    padding: 0 32px;
    align-items: stretch;
    @include breakpoint(medium down) {
        padding: 0 ;
    }

        & > .columns {
            @include breakpoint(medium down) {
                margin-bottom: 16px;
            }
        }
    } 


    .content-wrapper {
        height: 100%;
        background-color: #fff;
        

        .section-title {
            
            @include breakpoint(medium down) {
                font-size: 20px;
            }

            @include breakpoint(large) {
                font-size: 32px;
            }

        }

        p {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .tabs {
        height: 100%;
        background-color: #fff;
        
        @include breakpoint(medium down) {
           padding: 32px;
        }
        @include breakpoint(large) {
            padding: 48px;
        }
    }

    .content-container {
        padding: 0;
        background-color: #fff;
    }


    .tab-content {
        padding: 0;
        background-color: #fff;

        .section-header-small {
            margin-bottom: 12px;
            
            @include breakpoint(medium down) {
                font-size: 16px;
            }

            
        }

        p {
            font-weight: 400;
        }
    }

    .superbrands-tabs {
        margin-bottom: 32px;
    }

    .triggers-container {
        padding: 0;
        margin: 0;
        gap: 12px;
        justify-content: flex-start;
        align-items: flex-start;

        
        @include breakpoint(small only) {
            flex-direction: column;
        }

    }
    .tab-parent-trigger {
        flex: 0 0 auto;
    }

    .tab-trigger {
        height: 32px;
        padding: 12px;
        font-size: 12px;
        line-height: 1;
        border-radius: 16px;

        .active {
            font-family: inherit;
            font-weight: 400;
        }
        
    }
}