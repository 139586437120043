.online-test {
  .results {
    display: none;

    &.active {
      display: block;
    }
  }

  .results-answer {
    display: none;

    &.active {
      display: block;
    }
  } 

  .reload-link {
    margin-bottom: 32px;
  }

  .ref {
    padding-top: 16px;
    border-top: 1px solid $color-gray-border;
  }
}