#nn-widget-questionnaire.questionnaire-new {
    min-height: 350px !important;
    max-height: 350px !important;

    h2 {
        display: none !important;
    }

    div {
        box-shadow: none;
    }

    div:has(>form) {
        padding: 0 !important;
    }
    
    div:has(>ol) {
        display: none;
        height: 0;
    }

    ol {
        display: none;
        height: 0;
    }
    .enter-done ol {
        display: flex;
        height:auto
    }

    .enter-done div:has(>ol) {
        display: block;
        height: 120px;
    }

}
