.button-copy {
  color: $color-grey-dark;
  font-size: 16px;
  line-height: 1.25;

  img {
    max-width: 24px;
    margin-right: 8px;
  }

  &:hover {
    color: $color-orange;
  }
}
