.ike-ikze-table {
    overflow: hidden;
    position: relative;    
    --table-cols: 3;

    th,
    td {
        &:not(:first-child) {
            @media (min-width: 640px) {
                width: 38.7%;
            }
        }            
    }

    tbody td {
        text-align: center;
        height: 104px;
    }
    
    @media (max-width: 639px) {
        
        table {
            width: calc((100% * var(--table-cols)) - 80px);
        }

        tbody {
            td:nth-child(2) {
                padding-left: 92px;
                padding-right: 80px;
            }
        }        
    } 
}

.ike-ikze-table-data {
    padding: 0;
    position: relative;
    
    @media (max-width: 639px) {
        width: auto;
        overflow-x: scroll;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    th,
    td {    
        border-bottom: 1px solid #E0E0E0;
        font-size: 1rem;
        line-height: 1.25;
        font-weight: 400;
        vertical-align: middle;
        min-height: 64px;        
        color: #404040;

        b {
            font-weight: 500;
            font-family: $nndagny-display-normal;
        }
    }

    thead th {
        padding: .75rem .75rem .75rem 1.5rem;
        font-weight: 400;
        height: 56px;
        vertical-align: bottom;
        text-align: center;
        font-family: $nndagny-display-normal;

        .custom-tooltip {
            position: relative;
            top: -20px;
        }
    }

    tbody th {
        padding: .75rem .75rem .75rem 0;      

        @media (min-width: 640px) {
            width: 216px;
        }
        
        small {
            display: block;
            font-size: .75rem;
            color: #767676;
        }
    }

    tbody td {
        padding: .75rem .75rem .75rem 1.5rem;
    }

    .col-standard {
        background-image: linear-gradient(90deg, #fff 12px, #f7f7f7 12px);
    }
    
    .col-nn {
        background-image: linear-gradient(90deg, #fff 12px, #fff7ee 12px);
    }
    
    .col-header {
        background-color: #fff;
    }   

    .buttons-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: fit-content;
        align-items: center;
        margin: 0 auto;

        .btn {
            margin: 0;
            width: 100%;
        }

        .link {
            font-size: inherit;
        }
    }
}
.ike-ikze-table-nav {
    @media (max-width: 639px) {
        margin-top: 16px;
    }
}

.ike-ikze-table-nav--steps {  
    @media (min-width: 640px) {
        display: none;
    }

    @media (max-width: 639px) {
        display: flex; 
        justify-content: flex-end;
        align-items: center;  

        .step-counter {
            font-size: 1rem;
            color: #BDBDBD;

            .current-step {
                color: #404040;
            }
        }

        .prev,
        .next {
            display: block;
            border: none;
            width: 24px;
            height: 24px;

            &.disabled {
                pointer-events: none;
            }
        }

        .prev {
            background: transparent url("data:image/svg+xml;utf8,<svg width='9' height='17' viewBox='0 0 9 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.99999 1.2002L1 8.20019L7.99999 15.2002' stroke='%23EE7F00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") 50% / 9px 14px no-repeat;

            &.disabled {
                background: transparent url("data:image/svg+xml;utf8,<svg width='9' height='17' viewBox='0 0 9 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.99999 1.2002L1 8.20019L7.99999 15.2002' stroke='%23bdbdbd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") 50% / 9px 14px no-repeat;            
            }
        }
        
        .next {
            background: transparent url("data:image/svg+xml;utf8,<svg width='9' height='17' viewBox='0 0 9 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1.2002L7.99999 8.20019L1 15.2002' stroke='%23EE7F00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") 50% / 9px 14px no-repeat;
            
            &.disabled {
                background: transparent url("data:image/svg+xml;utf8,<svg width='9' height='17' viewBox='0 0 9 17' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1.2002L7.99999 8.20019L1 15.2002' stroke='%23bdbdbd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") 50% / 9px 14px no-repeat;
            }
        }
    }
}
